.slick-slider {
    .slick-arrow {
        background: transparent;
        z-index: 9;
        color: initial;
        font-size: medium;
        line-height: normal;

        &::before {
            content: none;
        }

    }

    .slick-dots {

        & > li {
            content: none;
            margin: 0;
            margin-left: 6px;
            height: auto;

            &:first-child {
                margin-left: 0;
            }
            width: 10px;
            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;
            -ms-transition: all 0.25s ease-in-out;
            -o-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;

            &.slick-active {
                width: 24px;
                height: 6px;
                -webkit-transition: all 0.25s ease-in-out;
                -moz-transition: all 0.25s ease-in-out;
                -ms-transition: all 0.25s ease-in-out;
                -o-transition: all 0.25s ease-in-out;
                transition: all 0.25s ease-in-out;
                & > button {
                    width: 24px;
                    height: 6px;
                    padding: 0;
                    -webkit-transition: all 0.25s ease-in-out;
                    -moz-transition: all 0.25s ease-in-out;
                    -ms-transition: all 0.25s ease-in-out;
                    -o-transition: all 0.25s ease-in-out;
                    transition: all 0.25s ease-in-out;

                    ::before {
                        content: none;
                    }
                }
            }
            & > button {
                border-radius: 2px;
                width: 10px;
                height: 4px;
                padding: 0;
                content: none;
                background-color: #fff;
                opacity: 0.5;
                border-radius: 6.25rem;

                &::before {
                    content: none !important;
                }
            }
        }
    }

}

.slick-slider.main-slider {
    .slick-arrow {
        opacity: 0;
        height: 3.75rem;
        width: 3.75rem;

        &::before {
            content: none;
        }

        svg {
            path {
                fill: rgba($color: #ffffff, $alpha: 0.4);
            }
        }

        @media (min-width: 600px) {
            display: none;
        }
    }

    .slick-arrow.slick-next {
        right: 0;
    }

    .slick-arrow.slick-prev {
        left: 0;
    }

    .slick-dots {
        bottom: 20px;

        & > li {

            &.slick-active {
                & > button {
                    opacity: 0.7;
                }
            }
            & > button {
                opacity: 0.5;
                background-color: #fff;
            }
        }
    }

    &:hover {
        .slick-arrow {
            opacity: 1;
        }
    }
}

.slick-slider.campaign-slider {
    & .slick-list { margin: 0 -7px; & .slick-slide > div { padding: 10px 15px; } }
    
    .slick-arrow {
        height: 60px;
        width: 60px;
        
        @media (max-width: 600px) {
            display: none !important;
        }
    }

    .slick-arrow.slick-next {
        right: -51px;
    }

    .slick-arrow.slick-prev {
        left: -51px;
    }

    .slick-dots {
        bottom: -30px;

        & > li {

            &.slick-active {
                & > button {
                    background-color: #9E9E9E;
                }
            }
            & > button {
                background-color: #BDBDBD;
            }
        }
    }
    
}
